import { createApp } from "vue";
import { createPinia } from "pinia";
import ToastPlugin from "vue-toast-notification";
import FloatingVue from "floating-vue";
import { LoadingPlugin } from "vue-loading-overlay";
import App from "./App.vue";
import router from "./router";
import "vue-loading-overlay/dist/css/index.css";
import "vue-toast-notification/dist/theme-sugar.css";
import "floating-vue/dist/style.css";
import "./assets/css/app.css";
import vue3GoogleLogin from "vue3-google-login";
import VueAwesomePaginate from "vue-awesome-paginate";
import { createVCodeBlock } from "@wdns/vue-code-block";
import * as Sentry from "@sentry/vue";
const app = createApp(App);
const VCodeBlock = createVCodeBlock({});
app.use(vue3GoogleLogin, {
  clientId:
    "960112056745-puejd10qmuk69foseanqme0qmvgjkp6m.apps.googleusercontent.com",
});
app.use(LoadingPlugin, {
  color: "#FFF",
  backgroundColor: "#000",
  opacity: 0.6,
  zIndex: 99999999999,
});
app.use(ToastPlugin, {
  duration: 5000,
});
app.use(FloatingVue, {
  themes: {
    tooltip: {
      placement: "bottom",
    },
  },
});
app.directive("click-outside", {
  beforeMount: function (el, binding) {
    const ourClickEventHandler = (event) => {
      if (!el.contains(event.target) && el !== event.target) {
        binding.value(event);
      }
    };
    el.__vueClickEventHandler__ = ourClickEventHandler;
    document.addEventListener("click", ourClickEventHandler);
  },
  unmounted: function (el) {
    document.removeEventListener("click", el.__vueClickEventHandler__);
  },
});
Sentry.init({
  app,
  dsn: "https://9e31b6dd2fbc090a3e2779ad381a846d@o4508185716326400.ingest.us.sentry.io/4508234767073280",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["SEO-Copilot", /^https:\/\/app\.seocopilotai\.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
app.use(VCodeBlock);
app.use(createPinia());
app.use(router);
app.use(VueAwesomePaginate);
app.mount("#app");
