<template>
  <div
    class="min-h-screen"
    :class="
      mode === 'dark'
        ? 'bg-dark-80 opacity-100 is-dark text-white-100'
        : 'bg-gray-20 bg-opacity-50 text-black-100'
    "
  >
    <div
      ref="sidebar"
      class="fixed top-0 left-0 z-[998] h-full px-2 duration-300 transition-all origin-left transform border-r without-scrollbar custom:top-[56px]"
      :class="[
        isOpen === true
          ? 'w-70 overflow-visible'
          : ' w-60 overflow-x-hidden overflow-y-auto',
        mode === 'dark' ? 'bg-dark-90 border-dark-70' : 'bg-white-100',
        isCollapsed ? 'custom:left-0' : 'custom:-left-full',
      ]"
      v-if="
        isLoggedIn &&
        $route.name !== 'signup' &&
        $route.name !== 'signup2' &&
        $route.name !== 'wordpress-authentication' &&
        !$route.meta.isHideSidebar
      "
    >
      <div
        class="sidebar-links text-[15px] py-2 flex flex-col justify-between h-full custom:h-sidebar"
        :class="[mode === 'dark' ? 'text-white-100' : 'text-gray-600']"
      >
        <div class="grid" :class="[isOpen ? 'gap-3' : 'gap-5']">
          <RouterLink
            :to="{ name: 'dashboard' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Dashboard' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Dashboard' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-center py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              @click="navigate"
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <LayoutDashboard :size="22" :stroke-width="1.75" />
              </span>
              <div :class="isOpen === true ? 'hidden' : ''">Dashboard</div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="userStore.hasKeywordPlannerPermission()"
            :to="{ name: 'KeywordPlanner' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Keyword Discovery' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Keyword Discovery' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive || isKeywordPlannerPage) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>START HERE Start with a base or seed keyword, and use
                  Advanced AI to discover the most lucrative and easiest
                  keywords and niches to go for! Plan Money Pages and Topical
                  clusters.</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <LayoutList :size="22" :stroke-width="1.75" />
              </span>
              <div :class="isOpen === true ? 'hidden' : ''">
                <div class="leading-none">Keyword Discovery</div>
                <div class="text-[11px] pt-2 leading-none font-medium">
                  Find Best Searches to Go For
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="userStore.hasTokenAnalysisPermission()"
            :to="{ name: 'serp-keyword-spy' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Keyword Spy' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Keyword Spy' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Use Keyword Spy to Spy on Your competitors and Build Your Own AI models and get your best SEO keywords</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <TextSearch :size="22" :stroke-width="1.75" />
              </span>
              <div :class="isOpen === true ? 'hidden' : 'mt-[1px]'">
                <div class="leading-none">Keyword Spy</div>
                <div class="text-[11px] pt-2 leading-none font-medium">
                  Find Boosting Keywords
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'editor' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Editor' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Article Builder' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>After Your Report is done, use Advanced AI to Generate / Optimize ALL of your SEO content: URLs, Titles, METAs, Headings, Images and Content with the Perfect Metrics!</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <FilePenLine :size="22" :stroke-width="1.75" />
              </span>
              <div :class="isOpen === true ? 'hidden' : ''">
                <div class="leading-none">Article Builder</div>
                <div class="text-[11px] pt-2 leading-none font-medium">
                  Automate Your OnPage SEO
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'reports' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Reports' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Reports' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive || isReportPages) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Check the Results on all of your Existing Keyword Metrics</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <FileChartColumnIncreasing :size="22" :stroke-width="1.75" />
              </span>
              <div :class="isOpen === true ? 'hidden' : ''">
                <div class="leading-none">Reports</div>
                <div class="text-[11px] pt-2 leading-none font-medium">
                  Investigate Boosting Keywords
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="featureFlag"
            :to="{ name: 'audit' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'OnPage Audit (Beta)' : ''"
            v-tooltip="{
              content: `${isOpen ? 'OnPage Audit (Beta)' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive || isAuditPage) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Check the Results on all of your Existing Keyword Metrics</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <BookOpenText :size="22" :stroke-width="1.75" />
              </span>
              <div :class="isOpen === true ? 'hidden' : ''">
                <div class="leading-none">OnPage Audit (Beta)</div>
                <div class="text-[11px] pt-2 leading-none font-medium">
                  AI SEO Audit Report
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'projects' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Projects' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Projects' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive || isProjectsPage) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Handy way to organize Reports into Projects.</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <FolderOpenDot :size="22" :stroke-width="1.75" />
              </span>
              <div :class="isOpen === true ? 'hidden' : ''">
                <div class="leading-none">Projects</div>
                <div class="text-[11px] pt-2 leading-none font-medium">
                  Group Your Reports
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="userStore.hasZebraAIPermission()"
            :to="{ name: 'zebra-ai' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Beta Tools' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Beta Tools' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Use AI to Analyze and Generate Helpful / Review Content, Workshop URL/Title/Meta/H1, AI Detection, AI Content Improver and Featured Snippet Generator!</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <Cog :size="22" :stroke-width="1.75" />
              </span>
              <div :class="isOpen === true ? 'hidden' : ''">
                <div class="leading-none">Beta Tools</div>
                <div class="text-[11px] pt-2 leading-none font-medium">
                  Check Out Our Beta AI Tools
                </div>
              </div>
            </div>
          </RouterLink>
          <div
            class="flex items-center py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 cursor-pointer group"
            :class="[
              isOpen && 'is-open justify-center',
              mode === 'dark'
                ? 'text-white-100 hover:bg-dark-70'
                : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
            ]"
            @click="viewCreditsStatus = true"
            :title="isOpen ? 'View Plan/Credits' : ''"
            v-tooltip="{
              content: `${isOpen ? 'View Plan/Credits' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
          >
            <span class="flex-shrink-0 w-[22px] h-[22px]">
              <CreditCard :size="22" :stroke-width="1.75" />
            </span>
            <span class="leading-none" :class="isOpen === true ? 'hidden' : ''"
              >View Plan / Credits</span
            >
          </div>
          <RouterLink
            :to="{
              name: 'seotraining',
            }"
            class="flex items-center py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
            :class="[
              isOpen && 'is-open justify-center',
              mode === 'dark'
                ? 'text-white-100 hover:bg-dark-70'
                : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
            ]"
            :title="isOpen ? 'Unlock SEO Training' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Unlock SEO Training' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
          >
            <span class="flex-shrink-0 w-[22px] h-[22px]">
              <Radio :size="22" :stroke-width="1.75" />
            </span>
            <div :class="isOpen === true ? 'hidden' : ''">
              <div class="leading-none">Unlock SEO Training</div>
            </div>
          </RouterLink>
        </div>
        <div
          class="flex items-center justify-center py-2 px-3 rounded-md gap-2.5 cursor-pointer"
          @click="toggleOpen"
          v-tooltip="{
            content: `${isOpen ? 'Collapse' : ''}`,
            placement: 'auto',
            distance: 10,
          }"
          :class="[
            mode === 'dark'
              ? 'text-white-100 bg-dark-70'
              : 'bg-primary-100 hover:text-black-100 bg-opacity-5',
          ]"
        >
          <span class="w-[22px] h-[22px]">
            <Maximize2 v-if="isOpen" :size="22" :stroke-width="1.75" />
            <Minimize2 v-else :size="22" :stroke-width="1.75" />
          </span>
        </div>
      </div>
    </div>
    <div
      class="transition flex-1 custom:ml-0"
      :class="[
        !isLoggedIn || $route.meta.isHideSidebar
          ? ''
          : isOpen === true
          ? 'ml-70'
          : 'ml-60',
      ]"
    >
      <RouterView :mode="mode" @toggle="toggle" :key="$route.path" />
    </div>
    <template v-if="isLoggedIn && $route.name !== 'signup'">
      <div
        v-if="viewCreditsStatus"
        class="fixed inset-0 z-[9999] flex items-center justify-center p-4 bg-opacity-40"
        :class="mode === 'dark' ? 'bg-white-100' : 'bg-black-100'"
      >
        <div
          class="max-w-3xl rounded-xl w-full mx-auto"
          :class="mode === 'dark' ? 'bg-dark-70' : 'bg-white-100'"
        >
          <div
            class="flex items-center justify-between p-4 border-b"
            :class="mode === 'dark' ? 'border-dark-50' : 'border-border-100'"
          >
            <h5 class="text-[17px] font-medium leading-none">Credits</h5>
            <X
              :size="22"
              :stroke-width="1.75"
              @click="viewCreditsStatus = false"
              class="cursor-pointer"
            />
          </div>
          <div class="bg-opacity-30 max-h-modal overflow-y-auto p-4">
            <div class="grid gap-4">
              <div class="flex items-center justify-between">
                <p class="font-medium text-[17px]">
                  Your Monthly Usage Stats and Plan Type
                </p>
                <div class="button primary-button" @click="addMoreCredits">
                  <CreditCard :size="20" :stroke-width="1.75" />
                  Add More Credits
                </div>
              </div>
              <ChartComponent />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed } from "vue";
import { productFruits } from "product-fruits";
import ApiRequest from "./libs/ApiRequest";
import helper from "./libs/helper";
import ChartComponent from "./components/ChartComponent.vue";
import { useUserStore } from "./stores/user";
import {
  BookOpenText,
  Cog,
  CreditCard,
  FileChartColumnIncreasing,
  FilePenLine,
  FolderOpenDot,
  LayoutDashboard,
  LayoutList,
  Maximize2,
  Minimize2,
  Radio,
  TextSearch,
  X,
} from "lucide-vue-next";
export default {
  setup() {
    const userStore = useUserStore();
    return {
      isLoggedIn: computed(() => userStore.isLoggedIn),
      accessToken: computed(() => userStore.accessToken),
      isLoading: computed(() => userStore.isloading),
      loaderLable: computed(() => userStore.loaderLable),
      isFullPage: computed(() => userStore.isFullPage),
      user: computed(() => userStore.user),
      userStore,
    };
  },
  components: {
    LayoutDashboard,
    LayoutList,
    TextSearch,
    FilePenLine,
    FileChartColumnIncreasing,
    FolderOpenDot,
    BookOpenText,
    Cog,
    Radio,
    CreditCard,
    Minimize2,
    Maximize2,
    X,
    ChartComponent,
  },
  data() {
    return {
      mode: localStorage.getItem("mode") || "light",
      loader: null,
      isOpen: false,
      viewCreditsStatus: false,
      isReportPages: false,
      isKeywordPlannerPage: false,
      isProjectsPage: false,
      isCollapsed: false,
      isAuditPage: false,
    };
  },
  methods: {
    addMoreCredits() {
      this.viewCreditsStatus = false;
      this.$router.push({ name: "account-subscription" });
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    toggle() {
      if (this.mode === "dark") {
        this.mode = "light";
      } else {
        this.mode = "dark";
      }
      localStorage.setItem("mode", this.mode);
    },
    onCancelLoader() {
      this.userStore.setIsLoading(!1);
    },
    async getCurrentUser() {
      try {
        const URL = `/api/auth/current-user`;
        const res = await ApiRequest().get(URL);
        if (res.status !== 200) {
          throw new Error("Something went wrong!");
        }
        this.userStore.setUser(res.data);
      } catch (error) {
        const extractedError = helper.extractAlert(error, "app");
        this.$toast.error(extractedError.alert.message);
      }
    },
    handleIsLoading() {
      const options = {
        canCancel: false,
        onCancel: this.onCancelLoader,
        loader: "spinner",
        height: 45,
      };
      if (this.isFullPage) {
        options["container"] = this.isFullPage;
      }
      if (this.isLoading) {
        this.loader = this.$loading.show(options, {
          after: this.loaderLable,
        });
      } else if (this.loader) {
        this.loader.hide();
      }
    },
    initProductFruits() {
      if (this.userStore.user && this.userStore.user.email) {
        const { email, first_name, last_name, phone_number } =
          this.userStore.user;
        if (!window.productFruits) {
          productFruits.init(
            "nG7StDYhiGiO5TOh",
            "en",
            {
              username: email,
              email,
              first_name,
              last_name,
              phone_number,
              props: {
                uuid: (this.$route.params && this.$route.params.uuid) || "",
                routeName: this.$route.name || "",
              },
            },
            {
              disableLocationChangeDetection: true,
            }
          );
        } else {
          if (window.productFruits.identifyUser) {
            window.productFruits.identifyUser({
              username: email,
              email,
              first_name,
              last_name,
              phone_number,
              props: {
                uuid: (this.$route.params && this.$route.params.uuid) || "",
                routeName: this.$route.name || "",
              },
            });
          }
        }
      }
    },
    redirect() {
      this.isCollapsed = false;
      window.dispatchEvent(
        new CustomEvent("isCollapsed", {
          detail: {
            isCollapsed: this.isCollapsed,
          },
        })
      );
    },
    async loadUserQuotas() {
      try {
        const { data } = await ApiRequest().get(`/api/auth/quotas`);
        if (data) {
          this.userStore.setQuotas(data);
        }
      } catch (error) {
        const extractedError = helper.extractAlert(error, "reports");
        this.$toast.error(extractedError.alert.message);
      }
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.loadUserQuotas();
    }
    this.initProductFruits();
    window.addEventListener("collapseSidebar", (event) => {
      this.isCollapsed = event.detail.isCollapsed;
    });
  },
  watch: {
    $route(to, from) {
      const reloadUserOnRoutes = [
        "subscription-confirmed",
        "subscription-upgrade",
        "subscription-cancelled",
        "account-subscription",
      ];
      if (reloadUserOnRoutes.includes(to.name)) {
        this.getCurrentUser();
      }
      if (["editor", "editor-public"].includes(to.name)) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
      if (to.name === "reports" || to.name === "serp-report-ta") {
        this.isReportPages = true;
      } else {
        this.isReportPages = false;
      }
      if (to.name.includes("KeywordPlanner")) {
        this.isKeywordPlannerPage = true;
      } else {
        this.isKeywordPlannerPage = false;
      }
      if (to.name === "projects") {
        this.isProjectsPage = true;
      } else {
        this.isProjectsPage = false;
      }
      if (to.name === "audit") {
        this.isAuditPage = true;
      } else {
        this.isAuditPage = false;
      }
      const steps = [
        "/keyword-discovery",
        "/serp/keyword-spy",
        "/serp/reports",
        "/article-builder",
      ];
      const currentIndex = steps.indexOf(to.path);
      if (currentIndex > -1) {
        this.userStore.setCurrentDashboardStep(currentIndex + 1);
      }
    },
    user(val) {
      if (val.email) {
        this.initProductFruits();
        this.loadUserQuotas();
      }
    },
    isLoading() {
      this.handleIsLoading();
    },
  },
  computed: {
    featureFlag() {
      return import.meta.env.VITE_API_BASE_URL.includes("staging");
    },
  },
};
</script>
