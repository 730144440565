<template>
  <div>
    <div
      class="grid gap-4 custom:grid-cols-1"
      :class="[type === '' ? 'grid-cols-2' : '']"
    >
      <div>
        <div
          class="p-3 bg-primary-100 text-white-100 rounded-tl-lg rounded-tr-lg"
        >
          <h6 class="font-semibold mb-2.5 leading-[1.25]">
            {{ tier }} On-page Tool
          </h6>
          <ul class="grid gap-1 list-disc pl-3">
            <li class="font-medium text-[15px]">
              {{ usedQuota }} Page Credits Used (Reports/Articles)
            </li>
            <li class="font-medium text-[15px]">
              {{ usedQuota + remainingQuota }} Total Page Credits
            </li>
          </ul>
        </div>
        <p
          class="px-3 bg-black-100 text-white-100 text-center py-2 rounded-bl-lg rounded-br-lg text-[15px]"
        >
          Page Credits {{ tier === "Trial" ? "expire" : "renew" }} on
          {{ this.subscribedUntil }}
        </p>
      </div>
      <div>
        <div
          class="p-3 bg-primary-100 text-white-100 rounded-tl-lg rounded-tr-lg"
        >
          <h6 class="font-semibold mb-2.5 leading-[1.25]">Keyword Discovery</h6>
          <ul class="grid gap-1 list-disc pl-3">
            <li class="font-medium text-[15px]">
              {{ difficulty_used_quota }} Keyword Difficulty Credits Used
            </li>
            <li class="font-medium text-[15px]">
              {{ difficulty_used_quota + difficulty_remaining_quota }} Total
              Keyword Difficulty Credits
            </li>
          </ul>
        </div>
        <p
          class="px-3 bg-black-100 text-white-100 text-center py-2 rounded-bl-lg rounded-br-lg font-medium text-[15px]"
        >
          Credits {{ tier === "Trial" ? "expire" : "renew" }} on
          {{ this.subscribedUntil }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import helper from "../libs/helper";
import { useUserStore } from "../stores/user";
import ApiRequest from "../libs/ApiRequest";
export default {
  name: "ChartComponent",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      difficulty_used_quota: 0,
      difficulty_remaining_quota: 0,
      usedQuota: 0,
      remainingQuota: 0,
      subscribedUntil: "",
      tier: "",
    };
  },
  mounted() {
    if (
      this.$route &&
      this.$route.name &&
      this.$route.fullPath.includes("article-builder/public")
    ) {
      return;
    }
    this.loadUserQuotas();
  },
  methods: {
    async loadUserQuotas() {
      try {
        const { data } = await ApiRequest().get(`/api/auth/quotas`);
        const {
          difficulty_used_quota,
          difficulty_remaining_quota,
          used_quota,
          remaining_quota,
          subscribed_until,
          tier,
        } = data;
        if (data) {
          this.userStore.setQuotas(data);
        }
        this.remainingQuota = remaining_quota ? parseInt(remaining_quota) : 0;
        this.usedQuota = used_quota ? parseInt(used_quota) : 0;
        this.difficulty_remaining_quota = difficulty_remaining_quota
          ? parseInt(difficulty_remaining_quota)
          : 0;
        this.difficulty_used_quota = difficulty_used_quota
          ? parseInt(difficulty_used_quota)
          : 0;
        this.subscribedUntil = moment(subscribed_until).format("DD MMMM YYYY");
        this.tier = tier;
      } catch (error) {
        const extractedError = helper.extractAlert(error, "reports");
        this.$toast.error(extractedError.alert.message);
      }
    },
  },
};
</script>
